import React, { useState } from 'react';
import Layout from '../components/Layout';
import axios from 'axios';

const CourseCard = ({ href, title, price }) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [sent, setSent] = useState(false);
  const [error, setError] = useState(false);

  const onSend = async () => {
    try {
      const res = await axios.post(`https://us-central1-iconic-mariner-146115.cloudfunctions.net/linearsolutions-contact`, {
        name,
        email,
        subject,
        message,
      });
      setSent(true);
    } catch (err) {
      console.log(err);
      setSent(true);
      setError(true);
    }
  };

  const pricingStr = price.split('\n').map((str) => <p style={{ fontSize: 14 }}>{str}</p>);

  return (
    <div style={styles.card}>
      <img src={'https://s26162.pcdn.co/wp-content/uploads/2022/05/Best-Reviewed-Books.jpeg'} alt="Avatar" style={{ width: '100%' }} />
      <div style={styles.container}>
        <h5>{title}</h5>
        {pricingStr}
        <a className="button primary" target="_blank" href={href}>
          View Materials
        </a>
      </div>
    </div>
  );
};

const styles = {
  card: {
    boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
    transition: '0.3s',
    maxWidth: 300,
    margin: 5,
  },
  container: {
    padding: '1px 16px',
    '&:hover': {
      boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2)',
      color: 'red',
    },
  },
};

export default CourseCard;
